<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/livingRoom.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Your responsibilites</span>
      </div>
      <div class="">
        <v-container class="px-5 py-5 py-sm-12">
          <p class="text-h6">ASSURED SHORTHOLD TENANCY AGREEMENT</p>
          <p>This agreement is made between:</p>
          <p>'The Landlord' and 'The Tenant'</p>
          <v-divider class="my-3"></v-divider>
          <p>1.The Landlord lets to the Tenant the residential Premises known as: </p>
          <p>2.The Tenancy shall start from and including the xxxx the Commencement Date’) and shall finish on andinclude xxxx</p>
          <p>3.The Tenant shall pay to the Landlord or the Landlord’s Agent - Thomas Morris Property Management - by
            way of rent the amount of £xxxx per calendar month by Bank Standing Order to: Account number: xxxx
            in the name of Thomas Morris Property Management exclusive of Council Tax and exclusive of Water
            Charges payable in advance on the 00st day of each month during the Term. The first such payment to
            be made on the signing
          </p>
          <p>4.This Agreement is intended to create an Assured Shorthold Tenancy as defined by Section 19A of the
              Housing Act 1988 as amended and shall take effect subject to the provisions for recovery of possession
              provided for by virtue of Section 21 of that Act.
          </p>
          <p>5.Where the Tenancy shall include the Landlord’s Fixtures and Fittings ('the Fixtures and Fittings') in the Premises this includes, amongst other things, all matters specified in the Inventory and Schedule of Condition (‘the Check-In Inventory and Schedule of Condition’). </p>
          <p>6.In the event of a default by the Tenant(s) of any of their obligations or responsibilities under the Agreement, the Guarantor(s) (if any) shall become immediately liable to remedy said default. In the event of multiple Guarantors, each shall be Joint and Severally liable.</p>
          <p class="text-h5">7. DEPOSIT</p>
          <p>7.1 The sum of xxxx shall be paid by The tenant, (‘The Relevant Person’) to the Landlord/Agent upon the signing of this Agreement by way of a security Deposit (‘the Deposit’).
          </p>
          <p>7.2 Upon the Tenant vacating the Premises and after deduction of all agreed or authorised
            deductions, the balance of the Deposit shall be refunded to the person or persons outlined
            at clause 7.1 above.
          </p>
          <p class="ml-5 font-weight-light">7.2.1 The Deposit will be held by the Agent as Stakeholder. The Agent is a memberof Tenancy Deposit Scheme.
          </p>
          <p>7.3 The Landlord’s Agent will protect the Deposit within 30 days of the commencement of the
            Tenancy or receipt of the Deposit, whichever is earlier, and give to the Tenant and to any
            Relevant Person a copy of the Prescribed Information together with details of the scheme
            applicable to the registration of the Deposit.
          </p>
          <p>7.4 Any interest earned on the holding of the Deposit will belong to Thomas Morris Property
            Management.
          </p>
          <p>7.5 The Deposit has been taken for the following purposes:</p>
          <p class="ml-5 font-weight-light">7.5.1 Any fees or other monies that the Agent is entitled to recover from theTenant.</p>
          <p class="ml-5 font-weight-light">7.5.2 Any rent or other money due or payable by the Tenant under the Tenancy of which
            the Tenant has been made aware and which remains unpaid after the end of
            the Tenancy. This will include a fee which any Agent is entitled to recover from
            the Tenant.
          </p>
          <p class="text-right text-h3">etc...</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      nextPage="/review/landlord"
      previousPage="/review/summary"
    />
  </div>
</template>

<script>
export default {
  name: 'agreement-your-responsibilities'
}
</script>

<style>

</style>